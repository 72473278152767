import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Container } from 'components/MarkupHelpers'
import { Grid, Cell } from 'components/CssGrid'
import { mediaBreakpointUp } from 'styles/media'

const LoginFormWrapper = styled.div`
  height: auto;
  padding-bottom: 30px;
  width: 100%;
  position: relative;
  ${mediaBreakpointUp('sm')`
    height: 380px;
    padding-bottom: 0;
  `};
`

const LoginLayout = ({ children }) => (
  <Container>
    <Grid>
      <Cell width={12} widthSm={8} widthLg={6} leftSm={3} leftLg={4}>
        <LoginFormWrapper>{children}</LoginFormWrapper>
      </Cell>
    </Grid>
  </Container>
)

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export const asLayout = page => <LoginLayout>{page}</LoginLayout>

export default LoginLayout
