import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reasonLoginSuccess as _reasonLoginSuccess } from '../../actions/reasonActions'
import { sessionSelector } from '../../selectors'
import ReasonLoginPage from './LoginPage.bs'

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ reasonLoginSuccess: _reasonLoginSuccess }, dispatch)
}

export default connect(
  state => ({ isLoggedIn: sessionSelector(state).isLoggedIn }),
  mapDispatchToProps
)(ReasonLoginPage)
