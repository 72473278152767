import Page from '../modules/Login/ReasonLoginPage'
import { requireUnauthenticated } from '../hoc/AuthenticatedComponent'
import { asLayout as loginLayout } from '../modules/Login/LoginLayout'
import { withMainLayout } from '../pagesComponents/MainApp'
import { compose } from 'ramda'

const LoginPage = requireUnauthenticated(Page)

LoginPage.getLayout = compose(withMainLayout, loginLayout)

export default LoginPage
