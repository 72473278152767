// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Axios = require("axios");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Config = require("../../config").default;
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var Core__Promise = require("@rescript/core/src/Core__Promise.bs.js");
var ReasonPromise = require("../ReasonPromise.bs.js");
var Utils__Promise = require("../../utils/Utils__Promise.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

var config = Config;

var apiPath = config.apiPath;

var instance = Axios.create({
      withCredentials: true
    });

function isEmailRegistered(email) {
  var encodedEmail = encodeURIComponent(email.trim());
  return Core__Promise.$$catch(instance.get(apiPath + "/auth/users/" + encodedEmail + "/registered").then(function (_response) {
                  return Promise.resolve({
                              TAG: "Ok",
                              _0: true
                            });
                }), (function (error) {
                var match = Core__Option.map(Caml_option.nullable_to_opt(Caml_js_exceptions.as_js_exn(error).response), (function (response) {
                        return response.status;
                      }));
                if (match === 404) {
                  return Promise.resolve({
                              TAG: "Ok",
                              _0: false
                            });
                } else {
                  return Promise.resolve({
                              TAG: "Error",
                              _0: "there was an error connecting to our servers"
                            });
                }
              }));
}

function postDataToJs(param) {
  return {
          username: param.username,
          password: param.password,
          rememberMe: param.rememberMe
        };
}

function postDataFromJs(param) {
  return {
          username: param.username,
          password: param.password,
          rememberMe: param.rememberMe
        };
}

var _map = {"agent":"agent","client":"client"};

var _revMap = {"agent":"agent","client":"client"};

function roleToJs(param) {
  return _map[param];
}

function roleFromJs(param) {
  return _revMap[param];
}

function parse(impersonated, js) {
  var role = _revMap[js.role];
  if (role !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              id: js.id,
              role: role,
              email: js.email,
              impersonated: impersonated
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              NAME: "INCOMPLETE_DATA",
              VAL: "unknown role: " + (js.role + " in login response")
            }
          };
  }
}

var UserInfo = {
  roleToJs: roleToJs,
  roleFromJs: roleFromJs,
  parse: parse
};

function login(email, password, rememberMe) {
  var postData = postDataToJs({
        username: email.trim(),
        password: password,
        rememberMe: rememberMe
      });
  return ReasonPromise.map(Utils__Promise.fromJsPromise(instance.post("/api/login", postData)), (function (x) {
                if (x.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: "INVALID_EMAIL_PASSWORD"
                        };
                }
                var data = x._0.data;
                if (data == null) {
                  return {
                          TAG: "Error",
                          _0: {
                            NAME: "INCOMPLETE_DATA",
                            VAL: "was none"
                          }
                        };
                }
                var impersonated = Core__Option.getOr(Caml_option.nullable_to_opt(data.impersonated), false);
                var __x = data.user;
                return Core__Result.map(parse(impersonated, __x), (function (userInfo) {
                              return {
                                      accessToken: data.accessToken,
                                      expiresIn: data.expiresIn,
                                      user: userInfo,
                                      impersonated: Core__Option.getOr(Caml_option.nullable_to_opt(data.impersonated), false)
                                    };
                            }));
              }));
}

exports.config = config;
exports.apiPath = apiPath;
exports.instance = instance;
exports.isEmailRegistered = isEmailRegistered;
exports.postDataToJs = postDataToJs;
exports.postDataFromJs = postDataFromJs;
exports.UserInfo = UserInfo;
exports.login = login;
/* config Not a pure module */
