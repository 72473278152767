// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var API = require("../../reason/common/API.bs.js");
var Next = require("../../reason/next/Next.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Sentry = require("../../reason/Sentry.bs.js");
var Scalars = require("../../scalars/Scalars.bs.js");
var EventLogs = require("../../reason/EventLogs.bs.js");
var LoginForm = require("./LoginForm.bs.js");
var PageTitle = require("../../reason/common/PageTitle.bs.js");
var Form__Jsx3 = require("../../uikit/reason/atoms/Form/Form__Jsx3.bs.js");
var Responsive = require("../../uikit/reason/helpers/Responsive.bs.js");
var Text__Jsx3 = require("../../uikit/reason/helpers/Text__Jsx3.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Atoms = require("../../reason/next/Next__Atoms.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var ReasonPromise = require("../../reason/ReasonPromise.bs.js");
var SystemMessage = require("../../uikit/reason/molecules/SystemMessage/SystemMessage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var MobileInputTextSize = require("../../v5/common/Form/MobileInputTextSize.bs.js");
var EmailRegistrationStatus = require("../../v5/common/Query/EmailRegistrationStatus.bs.js");
var MarkupHelpers = require("components/MarkupHelpers");

function LoginPage(props) {
  var reasonLoginSuccess = props.reasonLoginSuccess;
  var isLoggedIn = props.isLoggedIn;
  var recordEvent = EventLogs.useRecordEvent();
  var router = Next.useRouter();
  var userRole = React.useRef(undefined);
  var nextParam = Next.Router.query(router)["next"];
  var msgParam = Next.Router.query(router)["msg"];
  var redirectRoute = function (userRole) {
    return Core__Option.getOr(nextParam, userRole === "agent" ? "/" : "/client");
  };
  React.useEffect((function (param) {
          if (nextParam !== undefined) {
            Next.Router.prefetch(Caml_option.valFromOption(nextParam), router);
          } else {
            Next.Router.prefetch("/", router);
            Next.Router.prefetch("/client", router);
          }
        }), [nextParam]);
  var form = LoginForm.useForm({
        email: "",
        password: "",
        rememberMe: true
      }, {
        queryEmailAddress: EmailRegistrationStatus.queryFn
      }, (function (param, param$1) {
          var notifyOnFailure = param$1.notifyOnFailure;
          var notifyOnSuccess = param$1.notifyOnSuccess;
          ReasonPromise.get(API.login(Scalars.EmailAddress.toString(param.email), param.password, param.rememberMe), (function (x) {
                  if (x.TAG === "Ok") {
                    var data = x._0;
                    var match = data.user;
                    var role = match.role;
                    userRole.current = role;
                    Curry._1(notifyOnSuccess, undefined);
                    if (!data.impersonated) {
                      Curry._4(recordEvent, [
                            role === "agent" ? "USER" : "CLIENT",
                            match.id
                          ], undefined, "Login", undefined);
                    }
                    return reasonLoginSuccess(data);
                  }
                  var match$1 = x._0;
                  if (typeof match$1 === "object") {
                    Sentry.captureErrorMessage("Error in login response: " + match$1.VAL, true, undefined, "LoginPage.res", 62, undefined, undefined, undefined);
                    return Curry._1(notifyOnFailure, "An error occurred while logging in. Please try again or contact support");
                  } else {
                    return Curry._1(notifyOnFailure, "Your email/password weren't recognized");
                  }
                }));
        }));
  React.useEffect((function (param) {
          var match = form.status;
          if (typeof match !== "object" && match === "Submitted") {
            if (isLoggedIn) {
              Next.Router.push(redirectRoute(Core__Option.getOr(userRole.current, "agent")), undefined, undefined, router);
            }
            
          }
          
        }), [
        form.status,
        isLoggedIn,
        redirectRoute
      ]);
  var serverMessage = form.status;
  var tmp;
  tmp = typeof serverMessage !== "object" || serverMessage.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsx(SystemMessage.make, {
          id: "form_submission",
          time: 5000,
          text: serverMessage._0,
          type_: "error",
          dismissible: true,
          secondary: true,
          handleOnDismiss: (function (_id) {
              Curry._1(form.dismissSubmissionResult, undefined);
            })
        });
  var tmp$1;
  tmp$1 = msgParam === "password_change" ? JsxRuntime.jsx(View__Jsx3.make, {
          mb: "md",
          children: Caml_option.some(JsxRuntime.jsx(SystemMessage.make, {
                    id: "login_success",
                    time: 0,
                    text: "Your password was changed successfully!",
                    type_: "success",
                    secondary: true,
                    handleOnDismiss: (function (_id) {
                        
                      })
                  }))
        }) : null;
  var match = form.emailResult;
  var tmp$2;
  if (match !== undefined && match.TAG !== "Validating") {
    var message = match._0;
    tmp$2 = message.TAG === "Ok" ? null : JsxRuntime.jsx(Form__Jsx3.$$Error.make, {
            children: message._0
          });
  } else {
    tmp$2 = null;
  }
  var match$1 = form.passwordResult;
  var tmp$3;
  tmp$3 = match$1 !== undefined && match$1.TAG !== "Ok" ? JsxRuntime.jsx(Form__Jsx3.$$Error.make, {
          children: match$1._0
        }) : null;
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsx(PageTitle.make, {
                      title: "Login to Top Agent Network"
                    }),
                JsxRuntime.jsxs(MarkupHelpers.ContentBox, {
                      paddingFull: true,
                      hasShadow: true,
                      children: [
                        tmp,
                        tmp$1,
                        JsxRuntime.jsxs(View__Jsx3.make, {
                              children: [
                                JsxRuntime.jsx(Form__Jsx3.InputField.make, {
                                      labelText: "Email",
                                      onChange: (function ($$event) {
                                          Curry._2(form.updateEmail, (function (input, value) {
                                                  return {
                                                          email: value,
                                                          password: input.password,
                                                          rememberMe: input.rememberMe
                                                        };
                                                }), $$event.target.value);
                                        }),
                                      onBlur: (function (param) {
                                          Curry._1(form.blurEmail, undefined);
                                        }),
                                      status: Core__Option.flatMap(form.emailResult, (function (x) {
                                              if (x.TAG === "Validating") {
                                                return ;
                                              } else if (x._0.TAG === "Ok") {
                                                return "ok";
                                              } else {
                                                return "error";
                                              }
                                            })),
                                      value: form.input.email
                                    }),
                                tmp$2
                              ]
                            }),
                        JsxRuntime.jsxs(View__Jsx3.make, {
                              mt: "md",
                              children: [
                                JsxRuntime.jsx(Form__Jsx3.InputField.make, {
                                      labelText: "Password",
                                      _type: "password",
                                      onChange: (function ($$event) {
                                          Curry._2(form.updatePassword, (function (input, value) {
                                                  return {
                                                          email: input.email,
                                                          password: value,
                                                          rememberMe: input.rememberMe
                                                        };
                                                }), $$event.target.value);
                                        }),
                                      onBlur: (function (param) {
                                          Curry._1(form.blurPassword, undefined);
                                        }),
                                      status: Core__Option.map(form.passwordResult, (function (x) {
                                              if (x.TAG === "Ok") {
                                                return "ok";
                                              } else {
                                                return "error";
                                              }
                                            })),
                                      value: form.input.password
                                    }),
                                tmp$3
                              ]
                            }),
                        JsxRuntime.jsxs(View__Jsx3.make, {
                              mt: "md",
                              alignItems: Responsive.make("flexStart", "center", undefined, undefined, undefined, undefined, undefined),
                              justifyContent: Responsive.make("flexStart", "spaceBetween", undefined, undefined, undefined, undefined, undefined),
                              flexDirection: Responsive.make("column", "row", undefined, undefined, undefined, undefined, undefined),
                              children: [
                                JsxRuntime.jsx(Form__Jsx3.Checkbox.make, {
                                      checked: form.input.rememberMe,
                                      onChange: (function ($$event) {
                                          $$event.preventDefault();
                                          Curry._2(form.updateRememberMe, (function (input, value) {
                                                  return {
                                                          email: input.email,
                                                          password: input.password,
                                                          rememberMe: value
                                                        };
                                                }), !form.input.rememberMe);
                                        }),
                                      label: "Remember me"
                                    }),
                                JsxRuntime.jsx(View__Jsx3.make, {
                                      mt: Responsive.make("sm", "zero", undefined, undefined, undefined, undefined, undefined),
                                      children: Caml_option.some(JsxRuntime.jsx(Next__Atoms.Link.make, {
                                                href: "/reset_password",
                                                children: "Forgot your password?"
                                              }))
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(View__Jsx3.make, {
                              mt: "lg",
                              justifyContent: "flexEnd",
                              children: Caml_option.some(JsxRuntime.jsx(Buttons__Jsx3.PrimaryButton.make, {
                                        disabled: !Core__Option.getOr(Curry._1(form.valid, undefined), false) || form.submitting,
                                        size: "medium",
                                        children: "Sign in"
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(View__Jsx3.make, {
                      pt: "lg",
                      children: Caml_option.some(JsxRuntime.jsx(Text__Jsx3.make, {
                                textAlign: "center",
                                children: JsxRuntime.jsxs("p", {
                                      children: [
                                        "Not a member?  ",
                                        JsxRuntime.jsx("a", {
                                              children: "Request membership",
                                              href: "https://www.topagentnetwork.com/request-membership"
                                            }),
                                        " or ",
                                        JsxRuntime.jsx("a", {
                                              children: "learn more",
                                              href: "https://www.topagentnetwork.com"
                                            }),
                                        "."
                                      ]
                                    })
                              }))
                    })
              ],
              className: MobileInputTextSize.className,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            });
}

var View;

var responsive = Responsive.make;

var make = LoginPage;

var $$default = LoginPage;

exports.View = View;
exports.responsive = responsive;
exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* API Not a pure module */
